import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import RadioButtonRenderer from './RadioButtonRenderer';
import SelectDropdown from './SelectDropdown';
import DeleteButtonRenderer from './DeleteButtonRenderer';
import Button from '@mui/material/Button';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { connect } from "react-redux";
import {
    getAdminUsersActions,
    saveAdminUsersActions,
    deleteAdminUsersActions,
    createAdminUsersActions
} from "../../../Redux/Actions/Admin/adminAction"
import { ADMIN_SECURITY } from "../../../Constants/ComponentConstants/componentConstants"
import {
    adminFePayloadToBePayload,
    adminBePayloadToFePayload,
    getDifference,
    validateAdminData
} from "../../../Utils/AdminAndSecurity/adminSecurity.util"
import { v4 as uuidv4 } from 'uuid';

const AdminSettings = (props) => {
    const [uniqueTeamLeader, setUniqueTeamleaders] = useState(['-']);
    const [isEditable, setIsEditable] = useState(false);
    const {
        getAdminUsers,
        admin_user,
        deleteAdminUsers,
        saveAdminUsers,
        createAdminUsers,
    } = props;
    const gridRef = useRef(null);

    const [errorMsg, setErrorMsg] = useState('')
    const [apiRowdata, setApiRowData] = useState([])
    const [rowData, setRowData] = useState([]);
    const [deletedRecords, setDeletedRecords] = useState([]);
    const [validationErrMsg, setValidationErrMsg] = useState({userid: false, username: false});

    const updateAdmin = async (changes = []) => {
        setIsEditable(true)
        if (changes?.length === 0) {
            setIsEditable(false)
            return false
        }

        let returnValue = true
        const createUsers = []
        const saveUsers = []
        changes?.forEach(async (eachRows) => {
            if (validateAdminData(eachRows)) {
                if (apiRowdata.map(e => e.User).includes(eachRows.User)) 
                {
                    saveUsers.push(adminFePayloadToBePayload(eachRows))
                }
                else{
                    createUsers.push(adminFePayloadToBePayload(eachRows))
                }
            }
            else
                returnValue = false  
        })
        if (saveUsers.length > 0) {
            await saveAdminUsers(saveUsers)
        }
        if (createUsers.length > 0) {
            await createAdminUsers(createUsers)
        }
        if (returnValue) setIsEditable(false)
    }

    const handleEditableButtonClick = () => {
        if (!isEditable) {
            setIsEditable(true)
        } else {
            const editableRowData = getDifference(rowData, apiRowdata);
            if (deletedRecords.length > 0) {
                deleteAdminUsers(deletedRecords);
                setIsEditable(false);
            }
            if (editableRowData.length > 0) {
                updateAdmin(editableRowData);
                setIsEditable(false);
            }            
        }
    }
    useEffect(() => {
        if (admin_user?.error) setErrorMsg(admin_user?.error)
        else {
            setRowData(adminBePayloadToFePayload(admin_user))
            setApiRowData(rowData)
            const newTeamLeaders = rowData.filter(ds => ds.TeamLead === 'Yes').map(ds => ds.User);
            setUniqueTeamleaders(["-", ...newTeamLeaders]);
        }
    }, [admin_user])


    useEffect(() => {
        getAdminUsers()
    }, []);

    const onGridReady = (params) => {
        gridRef.current = params.api;
    }
    const updateGrid = (data, type = "add") => {
        if (type === "add") {
            if (gridRef.current) {
                gridRef.current.applyTransaction({
                    add: [data]
                });
            }
            const lastRow = gridRef.current;
            const lastRowIndex = lastRow.getDisplayedRowCount() - 1;
            lastRow.ensureIndexVisible(lastRowIndex);
            lastRow.setFocusedCell(lastRowIndex, 'User')
        }
        if (type === "update") {
            if (gridRef.current) {
                gridRef.current.applyTransaction({
                    update: [data]
                });
            }
        }
    }
    const handleAddRow = () => {
        const newItem = {
            id: uuidv4(), User: '', TeamLead: "No", Name: '', TeamLeader: '-', Delete: ''
        }
        updateGrid(newItem, "add")
        setRowData((prevData) => [...prevData, newItem]);
        gridRef.current.refreshCells({ force: true })
    }

    const handleDeleteRow = (data) => {      
        const adminData = rowData.filter((ds) => ds.User !== data.User);
        setRowData(adminData)

        const newTeamLeaders = adminData.filter(ds => ds.TeamLead === 'Yes').map(ds => ds.User);
        setUniqueTeamleaders(['-', ...newTeamLeaders]);
        adminData.map(e => {
            e.TeamLeader = newTeamLeaders.includes(e.TeamLeader) ? e.TeamLeader : "-"
            if (e.TeamLead === 'Yes') e.TeamLeader = "-"
            updateGrid(e, "update")
            return e
        })

        setDeletedRecords([...deletedRecords, data]);  
        const lastRow = gridRef.current;
        const lastRowIndex = lastRow.getDisplayedRowCount() - 1;
        lastRow.ensureIndexVisible(lastRowIndex);
        lastRow.setFocusedCell(lastRowIndex, 'User')  
    }

    useEffect(() => {
        setColDefs([
            {
                field: 'User',
                editable: isEditable,
                cellEditor: 'agTextCellEditor',
                cellRendererParams: {
                    isEditable: isEditable
                },
                cellRenderer: (params) => {
                    const showValidation = (!params.value || params.value.trim() === '')
                    setValidationErrMsg({...validationErrMsg, userid: showValidation});
                    if (params.isEditable) {
                        return (<div style={{ width: '100%', height: '100%'}}>
                            <input 
                                type="text"
                                value={params.value || ''}
                                onChange={(e) => params.setValue(e.target.value)}
                                style={{ width: '90%', height: '60%', border: showValidation ? '2px solid red' : ''}}
                            />
                            {showValidation && <div style={{color: 'red', fontSize: '12px'}}>Please enter User ID</div>}
                        </div>)
                    } else {
                        return (<>{params.data.User}</>)
                    }
                }
            }, {
                field: 'TeamLead',
                cellRenderer: RadioButtonRenderer,
                cellRendererParams: {
                    isEditable: isEditable
                }
            }, {
                field: 'Name',
                editable: isEditable,
                cellEditor: 'agTextCellEditor',
                cellRendererParams: {
                    isEditable: isEditable
                },
                cellRenderer: (params) => {
                    const showValidation = (!params.value || params.value.trim() === '');
                    setValidationErrMsg({...validationErrMsg, username: showValidation});
                    if (params.isEditable) {
                        return (<div style={{ width: '100%', height: '100%'}}>
                            <input 
                                type="text"
                                value={params.value || ''}
                                onChange={(e) => params.setValue(e.target.value)}
                                style={{ width: '90%', height: '60%', border: showValidation ? '2px solid red' : ''}}
                            />
                            {showValidation && <div style={{color: 'red', fontSize: '12px'}}>Please enter User Name</div>}
                        </div>)
                    } else {
                        return (<>{params.data.Name}</>)
                    }
                }
            }, {
                field: 'TeamLeader',
                cellRenderer: SelectDropdown,
                cellRendererParams: {
                    isEditable: isEditable,
                    uniqueTeamLeader: uniqueTeamLeader
                }
            }, {
                field: 'Delete',
                cellRenderer: DeleteButtonRenderer,
                hide: !isEditable,
                cellRendererParams: {
                    handleDelete: handleDeleteRow
                }
            }])
    }, [isEditable, uniqueTeamLeader])

    const [colDefs, setColDefs] = useState([
        { 
            field: 'User', 
            editable: isEditable,
            cellEditor: 'agTextCellEditor', 
            cellRendererParams: {
                isEditable: isEditable
            },
            cellRenderer: (params) => {
                const showValidation = (!params.value || params.value.trim() === '')
                setValidationErrMsg({...validationErrMsg, userid: showValidation});
                if (params.isEditable) {
                    return (<div style={{ width: '100%', height: '100%'}}>
                        <input 
                            type="text"
                            value={params.value || ''}
                            onChange={(e) => params.setValue(e.target.value)}
                            style={{ width: '90%', height: '60%', border: showValidation ? '2px solid red' : ''}}
                        />
                        {showValidation && <div style={{color: 'red', fontSize: '12px'}}>Please enter User ID</div>}
                    </div>)
                } else {
                    return (<>{params.data.User}</>)
                }
            }
        },
        {
            field: 'TeamLead',
            cellRenderer: RadioButtonRenderer,
            cellRendererParams: {
                isEditable: isEditable
            }
        },
        { 
            field: 'Name', 
            editable: isEditable,
            cellEditor: 'agTextCellEditor',
            cellRendererParams: {
                isEditable: isEditable
            },
            cellRenderer: (params) => {
                const showValidation = (!params.value || params.value.trim() === '')
                setValidationErrMsg({...validationErrMsg, username: showValidation});
                if (params.isEditable) {
                    return (<div style={{ width: '100%', height: '100%'}}>
                        <input 
                            type="text"
                            value={params.value || ''}
                            onChange={(e) => params.setValue(e.target.value)}
                            style={{ width: '90%', height: '60%', border: showValidation ? '2px solid red' : ''}}
                        />
                        {showValidation && <div style={{color: 'red', fontSize: '12px'}}>Please enter User Name</div>}
                    </div>)
                } else {
                    return (<>{params.data.Name}</>)
                }
            }
        },
        {
            field: 'TeamLeader',
            cellRenderer: SelectDropdown
        },
        {
            field: 'Delete',
            cellRenderer: DeleteButtonRenderer,
            hide: !isEditable,
            cellRendererParams: {
                handleDelete: handleDeleteRow
            }
        }
    ]);
    const gridOptions = {
        getRowId: params => params.data.id,
        rowHeight: 60
    };

    const onCellValueChanged = (params) => {
        setRowData(row => row)
        if (params.colDef.field === 'TeamLead') {
            const newTeamLeaders = rowData.filter(ds => ds.TeamLead === 'Yes').map(ds => ds.User);
            setUniqueTeamleaders(['-', ...newTeamLeaders]);
            rowData.map(e => {
                e.TeamLeader = newTeamLeaders.includes(e.TeamLeader) ? e.TeamLeader : "-"
                if (e.TeamLead === 'Yes') e.TeamLeader = "-"
                updateGrid(e, "update")
                return e
            })
        }
    }
    const onCellKeyDown = (params) => {
        rowData[params.rowIndex][params.colDef.field] = params.event.target.value
        updateGrid(params.data, "update")
        setRowData(row => row)
    }

    return (<>
        {
            !errorMsg ?
                <div>
                    <div style={{ display: 'flex', width: "90vw", justifyContent: 'right', marginBottom: '10px' }}>
                        <Button variant="outlined" onClick={handleEditableButtonClick}>
                            {isEditable ? 'Save Settings' : 'Edit Settings'}
                        </Button>
                    </div>
                    <div
                        className={
                            "ag-theme-quartz"
                        }
                        style={{ width: '90vw', height: '400px' }}
                    >
                        <AgGridReact
                            onGridReady={onGridReady}
                            gridOptions={gridOptions}
                            rowData={rowData}
                            columnDefs={colDefs}
                            onCellKeyDown={onCellKeyDown}
                            onCellValueChanged={onCellValueChanged}
                            defaultColDef={{
                                flex: 1,
                                minWidth: 100
                            }}
                            rowHeight={70}
                        />
                    </div>
                    {isEditable && <Button variant="outlined" onClick={handleAddRow} style={{ marginTop: '10px' }}>{ADMIN_SECURITY?.ADMIN_SETTINGS?.ADD_ROW}</Button>}
                </div>
                :
                <>{errorMsg}</>
        }
    </>

    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    getAdminUsers: getAdminUsersActions,
    saveAdminUsers: saveAdminUsersActions,
    deleteAdminUsers: deleteAdminUsersActions,
    createAdminUsers: createAdminUsersActions,
};

export default connect(mapStateToProps, mapActionToProps)(AdminSettings);
