import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BREADCRUMB_CONSTANT } from '../../Constants/breadCrumbConstant';
import { Grid, Typography } from "@mui/material";
import "../../Assets/Css/Breadcrumbs.css"; 

const Breadcrumbs = () => {
  const [pageBreadcrumbs, setPageBreadcrumbs] = React.useState([]);
  const location = useLocation();

  React.useEffect(() => {
    const { pathname } = location;
    const breadCrumbConst = BREADCRUMB_CONSTANT?.find(e => e?.PATH === pathname);
    if (breadCrumbConst) {
      const { BREADCRUMB = [] } = breadCrumbConst;
      setPageBreadcrumbs(BREADCRUMB);
    } else {
      setPageBreadcrumbs([]);
    }
  }, [location]);

  return (
    <Grid container className="container" spacing={1}>
      <Grid item xs={12}>
        
        <div className="spacer"></div> 
        <div className="breadcrumbWrapper">
          {
            pageBreadcrumbs.length > 0 &&
            pageBreadcrumbs?.map(({ page_name, path }, i) => (
              <Typography key={page_name} component="span">
                {i > 0 && ' > '}
                <Link to={path} className="styledLink">{page_name}</Link>
              </Typography>
            ))
            
          }
        </div>
      </Grid>
    </Grid>
  );
};

export default Breadcrumbs;
