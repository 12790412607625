import React from 'react';
import ReactDOM from 'react-dom/client';
import './Assets/Css/index.css';
import AppRoutes from './Component/AppRoutes';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { store, persistor } from './Redux/store';

import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundaryContext } from 'react-use-error-boundary';

import { LicenseManager } from 'ag-grid-enterprise';
const agGridKey = require('./ag-grid-key.json');

LicenseManager.setLicenseKey(agGridKey?.key);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundaryContext>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <AppRoutes />
        </Router>
      </PersistGate>
    </Provider>
  </ErrorBoundaryContext>,
);
reportWebVitals();
