export const apiToFeMapping = (data = {}, type = 'primary') => {
  const {
    clientInformation = {},
    membershipInformation = {},
    employerInformation = {},
    primaryAddressScrubbed = {},
    primaryAddressUnScrubbed = {},
    alternateAddress = {},
    privacyAddress = {},
    products = [],
    additionalInformation = {},
    complianceInformation = {},
  } = data;
  if (type === 'primary') {
    return {
      clientInformation,
      employerInformation,
      subscriberInformation: {
        ...data.subscriberInformation,
        relationshipWithEmployee:
          data?.subscriberInformation?.relationshipWithEE,
        relationshipWithEE: undefined,
      },
      primaryAddressScrubbed,
      primaryAddressUnScrubbed,
      alternateAddress,
      privacyAddress,
      products,
      additionalInformation,
      complianceInformation,
    };
  } else {
    return {
      clientInformation,
      employerInformation,
      subscriberInformation: {
        ...data.subscriberInformation,
        relationshipWithEmployee:
          data?.subscriberInformation?.relationshipWithEE,
        relationshipWithEE: undefined,
      },
      membershipInformation: {
        ...data.membershipInformation,
        relationshipWithEmployee:
          data?.membershipInformation?.relationshipWithEE,
        relationshipWithEE: undefined,
      },
      primaryAddressScrubbed,
      primaryAddressUnScrubbed,
      alternateAddress,
      privacyAddress,
      products,
      additionalInformation,
      complianceInformation,
    };
  }
};

export const getData = async (
  setShowLoading,
  selectedUser,
  getPrimarySubscriberData,
  getDependentData,
) => {
  setShowLoading(true);
  if (selectedUser.type === 'primary')
    await getPrimarySubscriberData(selectedUser);
  else await getDependentData(selectedUser);
  setShowLoading(false);
};
