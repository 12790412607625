import axios from 'axios';
import { emptyObject } from './common.util';
import { BODY_TYPE } from '../../Constants/apiConstant';
import { store } from '../../Redux/store';
axios.interceptors.request.use((config) => {
  const token = store?.getState()?.okta_token?.accessToken;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
const API_PATH =
  process.env.REACT_APP_ENVIRONMENT &&
  process.env.REACT_APP_ENVIRONMENT === 'mock'
    ? process.env.REACT_APP_BASE_URL_MOCK
    : process.env.REACT_APP_BASE_URL_QA;
const api = async (data) => {
  const { PATH, METHOD, PAYLOAD, PARAM, VERSION } = data;
  const URL = `${API_PATH}v${VERSION}/${PATH}`;
  switch (METHOD) {
    case 'get':
      return !emptyObject(PARAM || {})
        ? (
            await axios.get(`${URL}`, {
              params: { ...PARAM },
            })
          ).data
        : (await axios.get(`${URL}`)).data;
    case 'post':
      if (data.BODY_TYPE === BODY_TYPE.RAW || '')
        return (await axios.post(`${URL}`, PAYLOAD)).data;
      else if (data.BODY_TYPE === BODY_TYPE.X_WWW_FORM_URLENCODED) {
        const params = new URLSearchParams();
        if (!emptyObject(PARAM)) {
          Object.keys(PARAM).forEach((par) => {
            params.append(par, PARAM[par]);
          });
        }
        return (await axios.post(`${URL}`, params)).data;
      }
      break;
    case 'put':
      if (data.BODY_TYPE === BODY_TYPE.RAW || '')
        return (await axios.put(`${URL}`, PAYLOAD)).data;
      else if (data.BODY_TYPE === BODY_TYPE.X_WWW_FORM_URLENCODED) {
        const params = new URLSearchParams();
        if (!emptyObject(PARAM)) {
          Object.keys(PARAM).forEach((par) => {
            params.append(par, PARAM[par]);
          });
        }
        return (await axios.post(`${URL}`, params)).data;
      }
      break;
    case 'patch':
      if (data.BODY_TYPE === BODY_TYPE.RAW || '')
        return (await axios.patch(`${URL}`, PAYLOAD)).data;
      else if (data.BODY_TYPE === BODY_TYPE.X_WWW_FORM_URLENCODED) {
        const params = new URLSearchParams();
        if (!emptyObject(PARAM)) {
          Object.keys(PARAM).forEach((par) => {
            params.append(par, PARAM[par]);
          });
        }
        return (await axios.post(`${URL}`, params)).data;
      }
      break;
    case 'delete':
      if (data.BODY_TYPE === BODY_TYPE.RAW || '') {
        return (await axios.delete(`${URL}`, { data: PAYLOAD })).data;
      } else if (data.BODY_TYPE === BODY_TYPE.X_WWW_FORM_URLENCODED) {
        const params = new URLSearchParams();
        if (!emptyObject(PARAM)) {
          Object.keys(PARAM).forEach((par) => {
            params.append(par, PARAM[par]);
          });
        }
        return (await axios.post(`${URL}`, params)).data;
      }
      break;
    default:
      break;
  }
};

export default api;
