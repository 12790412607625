import React from "react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Grid, Typography } from "@mui/material";

const MembershipGrid = ({ onGridReady, data=[], title='', column=[], gridRef, defaultCofDef, domLayout='normal' }) => {
    return (
        <div className="subscriber-info-box container">
            <Grid xs={12}>
                <Typography sx={{ marginBottom: '8px', marginLeft: '5px' }} className="user-title-left">
                    {title || ''}
                </Typography>
                <div className="ag-theme-alpine productmodal-grid" style={{height: `${domLayout==='normal'?'120px':'175px'}`, marginLeft: '-8px' }}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={data || []}
                        columnDefs={column}
                        defaultColDef={defaultCofDef}
                        ref={(grid) => { gridRef.current = grid }}
                    />
                </div>
            </Grid>
        </div>
    )
}

export default MembershipGrid