import React, { useState, useRef, useContext } from "react";
import { connect } from "react-redux";
import AdvancedSearchScreen from './AdvancedSearchScreen';
import "../../../Assets/Css/BasicSearchScreen.css";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertTitle,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import Icon from "../../Common/Icon"
import CustomModal from '../../Common/CustomModal';
import { emptyObject } from "../../../Utils/Common/common.util"

import { inputAction } from "../../../Redux/Actions/Common/inputAction"
import { membershipSearchAction } from "../../../Redux/Actions/Membership/memberSearchAction"
import { ROUTES } from "../../../Constants/routesConstant"
import { Grid, Button, IconButton } from "@mui/material";


const BasicSearchScreen = (props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ firstName: '', lastName: '', birthday: '' });
  const [errorMsg, setErrorMsg] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const FirstNameRef = useRef(null);
  const LastNameRef = useRef(null);
  const BirthdayRef = useRef(null);
  const AdvancedSearchScreenButtonRef = useRef(null);
  const SearchButtonRef = useRef(null);
  const today = new Date().toISOString().split("T")[0];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setErrorMsg("");
  };
  const handleDateChange = (event) => {
    const inputDate = event.target.value;
    if (/^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
      const year = parseInt(inputDate.split("-")[0], 10);
      if (year < 1900 || inputDate > today) {
        alert("Please select the valid date");
        return false;
      } else {
        const { name, value } = event.target;
        setFormData(prevData => ({
          ...prevData,
          [name]: value
        }));
        return true;
      }
    }
  };
  const handleBasicSearch = async (e) => {
    e.preventDefault();
    const { firstName, lastName, birthday } = formData;
    const validCombinations = ((firstName && lastName)
      || (firstName && lastName && birthday)
      || (lastName));
    if (!validCombinations) {
      alert("Please click on Help icon in the Eligibility search widget to know valid combinations that has to be entered for getting search results");
      setTimeout(() => setErrorMsg(''), 7000);
      return;
    }
    props.input({ ...props.component_input, 'search': formData })
    if (props?.component_input?.search && !emptyObject(props?.component_input?.search)) {
      const searchQuery = {};
      if (firstName) {
        searchQuery['firstName:startsWith'] = firstName;
      }
      if (lastName) {
        searchQuery['lastName:startsWith'] = lastName;
      }
      if (birthday) {
        searchQuery['birthday'] = birthday;
      }
      await props.getSearchData(searchQuery)
      navigate(`${ROUTES.SUBSCRIBER.SEARCH_RESULT}`);
    }
  };

  const handleAdvancedSearch = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleTabNavigation = (e, ref) => {
    if (e.key === 'Enter') {
      handleBasicSearch(e);
    }
    if (e.key === 'Tab' && ref.current === document.activeElement) {
      e.preventDefault();
      if (ref === BirthdayRef) {
        AdvancedSearchScreenButtonRef.current.focus();
      } else {
        ref.current.focus();
      }
      if (ref === AdvancedSearchScreenButtonRef) {
        SearchButtonRef.current.focus();
      }
    }
  };


  return (
    <>
      <div className="Basicsearchform">
        <div className="basic-form-group-wrp">
          <div className="form-group">
            <p>Last name</p>
            <input
              className="form-group"
              type="text"
              placeholder="Enter last name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              ref={FirstNameRef}
              onKeyDown={(e) => handleTabNavigation(e, LastNameRef)}
            />
          </div>
          <div className="form-group">
            <p>First name(optional)</p>
            <input
              type="text"
              placeholder="Enter first name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              ref={LastNameRef}
              onKeyDown={(e) => handleTabNavigation(e, BirthdayRef)}
            />
          </div>
          <div className="form-group">
            <p className="title">Date of Birth(optional)</p>
            <input
              className="form-group-date"
              type="date"

              name="birthday"
              value={formData.birthday}
              onChange={handleChange}
              onBlur={handleDateChange}
              ref={BirthdayRef}
              onKeyDown={(e) => handleTabNavigation(e, BirthdayRef)}
              min="1900-01-01"
              max={today}
            />
          </div>
        </div>
        <Grid item xs={12} className="eligibility-error-btn">
          <Button className={`eligibility-search-btn`} variant="text" ref={AdvancedSearchScreenButtonRef} onClick={handleAdvancedSearch} >
            Advanced search
          </Button>
          <Button className={`view-all-error`} variant="contained" color="success" ref={SearchButtonRef} onClick={handleBasicSearch}>
            Search
          </Button>
        </Grid>
        {errorMsg && (
          <div className="pfserrmsg" id="errormsgs">
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMsg}
            </Alert>
          </div>
        )}
      </div>
      <Modal
        open={showPopup}
        onClose={handleClose}
        aria-labelledby="advanced-modal-title"
        aria-describedby="advanced-modal-description"
        fullWidth maxWidth="md"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box className="modal"
          sx={{
            position: 'relative',
            padding: 2,
            backgroundColor: 'transperant',
            border: 'none',
          }}>
          <AdvancedSearchScreen />
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 30,
              right: 20,
              zIndex: 1000,
            }}
          >
            <Icon name="CloseIcon" />
          </IconButton>

        </Box>
      </Modal>
    </>
  )
};

const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  input: inputAction,
  getSearchData: membershipSearchAction
};

export default connect(mapStateToProps, mapActionToProps)(BasicSearchScreen);

