export const PRODUCT_MODAL = {
  TITLE: {
    PRODUCT_LIST: 'Product list',
  },
};

export const colDef = [
  {
    headerName: 'Enterprise ID',
    field: 'productId',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Product Name',
    field: 'productName',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Product Description',
    field: 'productDescription',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Network Code',
    field: 'networkCode',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Effective Date',
    field: 'effectiveDate',
    sortable: true,
    unSortIcon: true,
  },
  {
    headerName: 'Termination Date',
    field: 'terminationDate',
    sortable: true,
    unSortIcon: true,
  },
];
export const gridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 10,
  },
};
export const defaultCofDef = {
  flex: 1,
  minWidth: 10,
};
