export const OKTA_SCOPES = [
  'openid',
  'email',
  'profile',
  'esrx.default',
  'offline_access',
];

export const ACTIVITY_EVENTS = [
  'mousedown',
  'mousemove',
  'keydown',
  'scroll',
  'touchstart',
];

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowX: 'auto',
};

export const COMMON_CLASS = {
  AG_THEME_ALPINE: 'ag-theme-alpine',
  CURSOR_POINTER: 'cursor-pointer',
};

export const COMMON_TEXT = {
  CUSTOM: 'custom',
};

export const AG_COLUMN_CENTER = {
  headerClass: 'text-center',
  cellStyle: {
    textAlign: 'center',
  },
};

export const CAPS_TO_SPACE = ['DOB'];

export const REPLACE_WORD = {
  Tpa: 'Payer',
  DOB: 'Date of Birth',
  Ssn: 'SSN',
  Id: 'ID',
};
