import { ROUTES } from './routesConstant';

const {
  DASHBOARD,
  SUBSCRIBER_DETAILS,
  DEPENDENT_DETAILS,
  SEARCH_RESULT,
  ADMIN_SECURITY,
  FILE_SEARCH,
  ERROR_TRENDS,
  FILE_TRACKING,
} = ROUTES;

export const BREADCRUMB_CONSTANT = [
  {
    PATH: ROUTES.SUBSCRIBER.SEARCH_RESULT,
    BREADCRUMB: [
      { page_name: 'Dashboard', path: DASHBOARD },
      {
        page_name: 'Eligibility Search Results',
        path: ROUTES.SUBSCRIBER.SEARCH_RESULT,
      },
    ],
  },
  {
    PATH: ROUTES.SUBSCRIBER.SUBSCRIBER_DETAILS,
    BREADCRUMB: [
      { page_name: 'Dashboard', path: DASHBOARD },
      { page_name: 'Search Results', path: ROUTES.SUBSCRIBER.SEARCH_RESULT },
      { page_name: 'Primary Participant Info', path: SUBSCRIBER_DETAILS },
    ],
  },
  {
    PATH: ROUTES.SUBSCRIBER.DEPENDENT_DETAILS,
    BREADCRUMB: [
      { page_name: 'Dashboard', path: DASHBOARD },
      { page_name: 'Search Results', path: ROUTES.SUBSCRIBER.SEARCH_RESULT },
      { page_name: 'Dependent Info', path: DEPENDENT_DETAILS },
    ],
  },
  {
    PATH: ADMIN_SECURITY,
    BREADCRUMB: [
      { page_name: 'Dashboard', path: DASHBOARD },
      { page_name: 'Admin & Security Setup', path: ADMIN_SECURITY },
    ],
  },
  {
    PATH: FILE_SEARCH,
    BREADCRUMB: [
      { page_name: 'Dashboard', path: DASHBOARD },
      { page_name: 'File Search Results', path: FILE_SEARCH },
    ],
  },
  {
    PATH: ERROR_TRENDS,
    BREADCRUMB: [
      { page_name: 'Dashboard', path: DASHBOARD },
      { page_name: 'Eligibility Error Trends', path: ERROR_TRENDS },
    ],
  },
  {
    PATH: FILE_TRACKING,
    BREADCRUMB: [
      { page_name: 'Dashboard', path: DASHBOARD },
      { page_name: 'File Tracking', path: FILE_TRACKING },
    ],
  },
];
