export const underscoreToCamel = (str = '') => {
  return str
    .split('_')
    .map((string) => string.charAt(0).toUpperCase() + string.slice(1))
    .join(' ');
};

export const underscoreToFirstCharCapital = (str = '') => {
  return str
    .split('_')
    .map((string, i) => {
      if (i === 0) return string.charAt(0).toUpperCase() + string.slice(1);
      else return string;
    })
    .join(' ');
};

export const emptyObject = (obj = {}) => {
  return Object?.keys(obj)?.length === 0 ? true : false;
};
export const getArrayPosition = (arr, findnumber) => {
  let rowNumber = -1;
  arr?.forEach((h, i) => {
    const prevValue = i > 0 ? arr[i - 1] : 0;
    if (
      (findnumber > prevValue && findnumber < h) ||
      (i === arr.length - 1 && findnumber > h)
    )
      rowNumber = i;
  });
  return rowNumber;
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
export const capitalizeFirstLetter = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const addSpaceBeforeCaps = (str = '') => {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (char === char.toUpperCase() && i !== 0) {
      result += ' ' + char;
    } else {
      result += char;
    }
  }
  return result;
};

export const emptyObjectWithEmptyKeys = (obj = {}) => {
  if (emptyObject(obj)) return true;
  else {
    let isEmptyKey = true;
    Object?.keys(obj)?.forEach((key) => {
      if (obj[key]) isEmptyKey = false;
    });
    return isEmptyKey;
  }
};
