import React from 'react';

const ErrorDetails = () => {
    return (
        <>
            <h1 className="searchtitle">Error Details</h1>
            <div className="line"></div>
            <div className="ag-theme-alpine" style={{ height: '100vh', width: '100%', padding: '40px' }}>
                </div>
          
        </>
    );
};

export default ErrorDetails;