import { FILE_TRACKING, FILE_DETAILS } from '../../../Constants/actionTypes';
import fileTracking from '../../../Dummydata/file-tracking.json';
import fileDetails from '../../../Dummydata/file-details.json';

export const fileTrackingAction = () => {
  return (dispatch) => {
    dispatch({
      type: FILE_TRACKING,
      payload: {
        fileTracking,
      },
    });
  };
};

export const fileDetailsAction = () => {
  return (dispatch) => {
    dispatch({
      type: FILE_DETAILS,
      payload: {
        fileDetails,
      },
    });
  };
};
