import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import {
    FormControl
} from '@mui/material';
import { Button } from '@mui/material';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { v4 as uuidv4 } from 'uuid';

const DropdownWithMultiselect = (props) => {
    const [isEditable, setIsEditable] = useState(false);
    const { admin_user = [], saveAdminUsers, getAdminUsers } = props;
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);

    const Options = [
        { value: "0125567", label: "0125567" },
        { value: "0125568", label: "0125568" },
        { value: "0125569", label: "0125569" },
        { value: "0125572", label: "0125572" },
        { value: "0125573", label: "0125573" }
    ];

    const onGridReady = (params) => {
        gridRef.current = params.api;
    }

    useEffect(() => {
        const securityData = admin_user?.map((user, index) => {
            const { userId, name, selectAll, whitelist, blacklist } = user;
            return { id: uuidv4(), user: userId, name: name, selectAll, whitelist: !selectAll ? whitelist?.filter(ds => ds.value !== "AllPayers") : [{ value: "AllPayers", label: "All Payers" }], blacklist }
        })
        setRowData([...[], ...securityData]);
        setIsEditable(false)
    }, [admin_user])

    const handleCheckboxChange = (params = {}) => {
        const { data: { id } } = params
        const adminUser = admin_user.filter((user) => user.userId === params?.data?.user)[0]
        setRowData((prevRowData) =>
            prevRowData.map((row) => {
                return row.id === id ? { ...row, whitelist: !row.selectAll ? [{ value: "AllPayers", label: "All Payers" }] : adminUser.whitelist?.filter(ds => ds.value !== "AllPayers"), blacklist: row.selectAll ? [] : adminUser.blacklist, selectAll: !row.selectAll } : row
            })
        );

        const lastRow = gridRef.current;
        const lastRowIndex = lastRow.getDisplayedRowCount() - 1;
        lastRow.ensureIndexVisible(lastRowIndex);
        lastRow.setFocusedCell(lastRowIndex, 'User')  
    };

    const handleSelectChange = (id, type, selectedOptions) => {
        setRowData((prevRowData) =>
            prevRowData.map((row) =>
                row.id === id ? { ...row, [type]: selectedOptions } : row
            )
        );
    }

    const handleEditableButtonClick = async (event) => {
        event.preventDefault();
        if (!isEditable) {
            setIsEditable(true)
        } else {
            setIsEditable(true)
            const saveUsers = rowData.map(data => {
                return {
                    userId: data.user,
                    selectAll: data.selectAll,
                    whitelist: data.whitelist,
                    blacklist: data.blacklist
                }
            });
            if (saveUsers.length > 0) {
                await saveAdminUsers(saveUsers)
                await getAdminUsers()
            }
        }
    }

    const gridOptions = {
        getRowId: params => params.data.user,
        rowHeight: 60
    };

    const columnDefs = [
        { 
            headerName: "User", 
            field: 'user', 
            editable: false 
        },
        { 
            headerName: "Name",
            field: 'name', 
            editable: false
        },
        {
            headerName: "Select All Payers",
            field: "selectAll",
            cellRenderer: (params) => (
                <>
                    {
                        isEditable ? <input
                            type="checkbox"
                            checked={params?.data?.selectAll}
                            onChange={() => handleCheckboxChange(params)}
                        /> : <input
                                disabled
                                type="checkbox"
                                checked={params?.data?.selectAll}
                                onChange={() => handleCheckboxChange(params)}
                            />
                    }
                </>

            ),
            width: 100
        },
        {
            headerName: "Whitelisted Payers",
            field: "whitelistedPayers",
            cellRenderer: (params) => (
                <FormControl sx={{ m: 1, width: 300 }} size="small" FullWidth>
                    {isEditable ? <Select
                        isMulti
                        isDisabled={params?.data?.selectAll}
                        options={Options}
                        value={params?.data?.whitelist}
                        onChange={(selectedOptions) => {
                            handleSelectChange(params?.data?.id, "whitelist", selectedOptions)
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 })
                        }}
                    />
                        :
                        <Select
                            isMulti
                            isDisabled={true}
                            options={Options}
                            value={params?.data?.whitelist}
                            onChange={(selectedOptions) =>
                                handleSelectChange(params?.data?.id, "whitelist", selectedOptions)
                            }
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 })
                            }}
                        />}

                </FormControl>
            ),
            width: 200
        },
        {
            headerName: "Blacklisted Payers",
            field: "blacklistedPayers",
            cellRenderer: (params) => (
                <FormControl sx={{ m: 1, width: 300 }} size="small" FullWidth>
                    {
                        isEditable ? <Select
                            isMulti
                            isDisabled={!params?.data?.selectAll}
                            options={Options}
                            value={params?.data?.blacklist}
                            onChange={(selectedOptions) =>
                                handleSelectChange(params?.data?.id, "blacklist", selectedOptions)
                            }
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 })
                            }}
                        />
                            :
                            <Select
                                isMulti
                                isDisabled={true}
                                options={Options}
                                value={params?.data?.blacklist}
                                onChange={(selectedOptions) =>
                                    handleSelectChange(params?.data?.id, "blacklist", selectedOptions)
                                }
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                                }}
                            />
                    }

                </FormControl>
            ),
            width: 200
        },
    ];

    return (
        <div>
            <div style={{ display: 'flex', width: "90vw", justifyContent: 'right', marginBottom: '10px' }}>
                <Button variant="outlined" onClick={handleEditableButtonClick}>
                    {isEditable ? 'Save Settings' : 'Edit Settings'}
                </Button>
            </div>
            <div
                className={
                    "ag-theme-quartz"
                }
                style={{ width: '90vw', height: '400px' }}
            >
            
                <AgGridReact
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100
                    }}
                    rowHeight={70}
                />
            </div>
        </div>
    )
}

export default DropdownWithMultiselect;