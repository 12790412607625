import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { selectedUserReducer } from './Reducers/Membership/selectedUserReducer';

import { apiReducer } from './Reducers/apiReducer';
import { membershipReducer } from './Reducers/Membership/membershipReducer';
import { tokenGenerationReducer } from './Reducers/tokenGenerationReducer';
import { membershipSearchReducer } from './Reducers/Membership/membershipSearchReducer';
import { inputReducer } from './Reducers/inputReducer';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { dashboardSettingsReducer } from './Reducers/Dashboard/dashboardSettingsReducer';
import { oktaTokenReducer } from './Reducers/oktaTokenReducer';
import { errorTrendsMTDReducer } from './Reducers/Widgets/errorTrendsMTDReducer';
import { eligibilityErrorsMTDReducer } from './Reducers/Widgets/eligibilityErrorsMTDReducer';
import { eligibilityErrorsRejMTDReducer } from './Reducers/Widgets/eligibilityErrorsRejMTDReducer';
import { payerErrorFileRatioMTDReducer } from './Reducers/Widgets/payerErrorFileRatioMTDReducer';
import {
  getAdminReducer,
  getTPAReducer,
  getUserTPAReducer,
} from './Reducers/Admin/getAdminTPAReducer';
import { membershipProductReducer } from './Reducers/Membership/getMembershipProductReducer';
import { eligibilityErrorResultsReducer } from './Reducers/Errorflow/eligibilityErrorResultsReducer';
import {
  fileTrackingReducer,
  fileDetailsReducer,
} from './Reducers/Widgets/fileTrackingReducer';

const middleware = [thunk];
const enhancers = [applyMiddleware(...middleware)];
const composedEnhancer = compose(...enhancers);

const persistConfig = {
  key: 'root',
  storage,
};

const allReducers = combineReducers({
  selectedUser: selectedUserReducer,
  apiData: apiReducer,
  membership: membershipReducer,
  access_token: tokenGenerationReducer,
  member_list: membershipSearchReducer,
  component_input: inputReducer,
  dashboard_settings: dashboardSettingsReducer,
  okta_token: oktaTokenReducer,
  admin_user: getAdminReducer,
  get_tpa: getTPAReducer,
  get_user_tpa: getUserTPAReducer,
  mtd_data: errorTrendsMTDReducer,
  eligerr_mtddata: eligibilityErrorsMTDReducer,
  eligerrrej_mtddata: eligibilityErrorsRejMTDReducer,
  payererrratio_mtd: payerErrorFileRatioMTDReducer,
  member_product: membershipProductReducer,
  eligerror_result: eligibilityErrorResultsReducer,
  file_tracking: fileTrackingReducer,
  file_details: fileDetailsReducer,
});

const persistedReducer = persistReducer(persistConfig, allReducers);

export const store = createStore(
  persistedReducer,
  {
    selectedUser: {},
    apiData: {},
    membership: {},
    access_token: '',
    member_list: [],
    component_input: {},
    dashboard_settings: {},
    okta_token: {},
    admin_user: [],
    get_tpa: [],
    get_user_tpa: [],
    mtd_data: {},
    eligerr_mtddata: {},
    eligerrrej_mtddata: {},
    payererrratio_mtd: {},
    member_product: [],
    eligerror_result: {},
    file_tracking: [],
    file_details: [],
  },
  composedEnhancer,
);

export const persistor = persistStore(store);
