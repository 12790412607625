import React from "react"
import { Grid, Button } from "@mui/material";
import { ROUTES } from "../../../Constants/routesConstant"
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { errorTrendsMTDAction } from '../../../Redux/Actions/Widgets/errorTrendsMTDAction';

const EligibilityErrorsTrends = (props) => {
    // const { item } = props;
    const { totalError, getMTDData, mtd_data } = props;
    const [links, setLinks] = React.useState([]);
    const objtoarr = (obj) => {
        if (!obj || !obj.payerPercentages)
            return;
        const tempLink = [];
        Object.keys(obj?.payerPercentages).forEach(elm => {
            if (!tempLink.map(txt => txt.text).includes(elm))
                tempLink.push({ text: elm, value: obj?.payerPercentages[elm] })
        })
        setLinks([...[], ...tempLink])
    };
    React.useEffect(() => {
        objtoarr(mtd_data);
    }, [mtd_data]);

    React.useEffect(() => {
        getMTDData({ range: props.mtdItem })
    }, [props.mtdItem])

    const navigate = useNavigate();
    const handleViewTrends = () => {
        navigate(`${ROUTES.ERROR_TRENDS}`);
    };
    return (<div style={{ padding: '20px 0px 0px 0px' }}>
        <Grid container>
            <Grid id={`current-index-${props.currIndex}`} item style={{ fontWeight: 'bold' }} xs={9}>{totalError}</Grid>
            <Grid item className={`text-center`} style={{ fontWeight: 'bold' }} xs={3}>{mtd_data?.totalErrors}</Grid>
        </Grid>
        {links.map(link => {
            return (
                <Grid key={JSON.stringify(link)} container>
                    <Grid item className={`error-container widget-anchor common-line`} xs={9.5}>
                        <a href="#"
                            className={`widget-anchor`}>
                            {link.text}</a>
                    </Grid>
                    <Grid item className={`text-right error-container common-line`} xs={2}>{link.value}</Grid>
                </Grid>
            )
        })}
        <Grid item xs={12} className="eligibility-error-btn">
            <Button className={`view-all-error`} variant="contained" color="success" size="small" onClick={handleViewTrends}>
                View Trends
            </Button>
        </Grid>
    </div>)
}

const mapStateToProps = (state) => {
    return { ...state };
};

const mapActionToProps = {
    getMTDData: errorTrendsMTDAction
};

export default connect(mapStateToProps, mapActionToProps)(EligibilityErrorsTrends);