import {
  FETCHING_DATA,
  ELIGIBILITY_ERRORSREJ_MTD_API,
} from '../../../Constants/actionTypes';
import APIS from '../../../Constants/apiConstant';
import { apiAction } from '../API/apiAction';

export const eligibilityErrorsRejMTDAction = (payload = {}) => {
  return async (dispatch) => {
    dispatch({
      type: FETCHING_DATA,
      payload: {
        apiData: APIS.find(
          ({ NAME }) => NAME === ELIGIBILITY_ERRORSREJ_MTD_API,
        ),
      },
    });
    await apiAction(dispatch, ELIGIBILITY_ERRORSREJ_MTD_API, payload);
  };
};
