import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { fileDetailsAction } from "../../../Redux/Actions/Widgets/fileTrackingAction"
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import {
    TITLE,
    FILE_DETAILS_COLUMN,
    CLASS_NAME
} from "../../../Constants/ComponentConstants/WidgetConstants/PayerEligibilityFileTrackingConstants/fileTrackingConstants"
import { COMMON_CLASS } from "../../../Constants/constants"

const FileDetails = (props) => {
    const [rowData, setRowData] = useState([]);
    const { getData, file_details: { fileDetails } } = props
    const { AG_THEME_ALPINE } = COMMON_CLASS
    const { FILE_DETAILS } = TITLE
    const { SEARCH_TITLE, LINE, FILE_DETAILS_GRID_CLASS } = CLASS_NAME
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setRowData(fileDetails)
    }, [fileDetails])

    return (
        <>
            <h1 className={SEARCH_TITLE}>{FILE_DETAILS}</h1>
            <div className={LINE}></div>
            <div className={`${AG_THEME_ALPINE} ${FILE_DETAILS_GRID_CLASS}`}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={FILE_DETAILS_COLUMN}
                />
            </div></>
    )
}

const mapStateToProps = (state) => {
    const { file_details } = state
    return { file_details };
};

const mapActionToProps = {
    getData: fileDetailsAction
};

export default connect(mapStateToProps, mapActionToProps)(FileDetails);