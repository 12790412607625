export const TITLES = {
  PRODUCT: 'Products',
  COMPLIANCE: 'Compliance',
};

export const COMPLIANCE_COLUMN = [
  {
    headerName: 'State of Situs',
    field: 'stateofSitus',
    minWidth: 200,
  },
  {
    headerName: 'Grandfathering Indicator',
    field: 'grandfathering',
    minWidth: 250,
  },
  {
    headerName: 'ERISA Status',
    field: 'erisaStatus',
    minWidth: 200,
  },
  {
    headerName: 'Funding Type',
    field: 'fundingType',
    minWidth: 200,
  },
  {
    headerName: 'Coverage Type',
    field: 'coverageType',
    minWidth: 200,
  },
  {
    headerName: 'Native American Indicator',
    field: 'nativeAmericanIndicator',
    minWidth: 300,
  },
  {
    headerName: 'Essential Health Benifit (EHB) State',
    field: 'ehbState',
    minWidth: 300,
  },
  {
    headerName: 'Benefit Metal Level Code',
    field: 'benefitMetalLevelCode',
    minWidth: 300,
  },

  {
    headerName: 'Legal Entity',
    field: 'legalEntity',
    minWidth: 150,
  },

  {
    headerName: 'Foreign Language Requirement Indicator',
    field: 'foreignLangRequIndicator',
    minWidth: 300,
  },
  {
    headerName: 'Foreign Language As Primary',
    field: 'foreignLangAsPrimary',
    minWidth: 300,
  },
];

export const CLIENT_INFORMATION_COLUMN = [
  {
    headerName: 'Payer Org ID',
    field: 'tpaOrg',
  },
  {
    headerName: 'Payer Name',
    field: 'tpaName',
  },
  {
    headerName: 'Payer Effective Date',
    field: 'tpaEffectiveDate',
  },
  {
    headerName: 'Payer Termination Date',
    field: 'tpaTerminationDate',
  },
];

export const EMPLOYER_GROUP_INFORMATION_COLUMN = [
  {
    headerName: 'Cigna Group ID',
    field: 'cignaGroupId',
    minWidth: 100,
  },
  {
    headerName: 'Client Assigned Group Number',
    field: 'clientAssignedGroupNumber',
    minWidth: 100,
  },
  {
    headerName: 'Group Name',
    field: 'groupName',
    minWidth: 100,
  },
  {
    headerName: 'Group Effective Date',
    field: 'groupEffectiveDate',
    minWidth: 100,
  },
  {
    headerName: 'Group Termination Date',
    field: 'groupTerminationDate',
    minWidth: 100,
  },
  {
    headerName: 'Group Org ID',
    field: 'groupOrgId',
    minWidth: 100,
  },
  {
    headerName: 'Group Org ID Description',
    field: 'groupOrgIdDesc',
    minWidth: 100,
  },
];

export const SUBSCRIBER_INFORMATION_COLUMN = [
  {
    headerName: 'First Name',
    field: 'firstName',
    minWidth: 200,
  },
  {
    headerName: 'Middle Name',
    field: 'middleName',
    minWidth: 200,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    minWidth: 200,
  },
  {
    headerName: 'Suffix',
    field: 'suffix',
    minWidth: 100,
  },
  {
    headerName: 'Relationship with Employee',
    field: 'relationshipWithEE',
    minWidth: 250,
  },
  {
    headerName: 'Group Org ID',
    field: 'groupOrgId',
    minWidth: 200,
  },
  {
    headerName: 'Date of Birth',
    field: 'DOB',
    minWidth: 200,
  },
  {
    headerName: 'Legal Gender',
    field: 'legalGender',
    minWidth: 200,
  },
  {
    headerName: 'SSN',
    field: 'ssn',
    minWidth: 200,
  },
  {
    headerName: 'Membership ID',
    field: 'membershipId',
    minWidth: 200,
  },
  {
    headerName: 'Alternate Member ID',
    field: 'alternateMemberId',
    minWidth: 200,
  },
  {
    headerName: 'Internal Group Number',
    field: 'groupNumber',
    minWidth: 300,
  },
  {
    headerName: 'Person Number',
    field: 'personNumber',
    minWidth: 200,
  },
];

export const ADDITIONAL_INFORMATION_COLUMN = [
  {
    headerName: 'Email Address',
    field: 'email',
  },
  {
    headerName: 'COBRA Indicator',
    field: 'cobraIndicator',
  },
  {
    headerName: 'Phone Number',
    field: 'phoneNumber',
  },
  {
    headerName: 'Medicare Primary Indicator',
    field: 'medicarePrimaryIndicator',
  },
  {
    headerName: 'Date of Death',
    field: 'death_day',
  },
];
