import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { getPrimarySubscriberAction, getDependentAction } from "../../../Redux/Actions/Membership/membershipAction"
import {
  emptyObject,
  underscoreToCamel,
  addSpaceBeforeCaps,
  capitalizeFirstLetter,
  emptyObjectWithEmptyKeys
} from "../../../Utils/Common/common.util"
import Loading from "../../Common/Loading";
import { ROUTES } from "../../../Constants/routesConstant"
import "../../../Assets/Css/SubscriberDetails.css"
import "../../../Assets/Css/UserInformationScreen.css";
import {
  colDef,
  defaultCofDef
} from "../../../Constants/ComponentConstants/MembershipConstants/DetailsConstants/productModalConstants"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { apiToFeMapping, getData } from "../../../Utils/Membership/Details/subscriber.util"
import {
  TITLES,
  COMPLIANCE_COLUMN,
  CLIENT_INFORMATION_COLUMN,
  EMPLOYER_GROUP_INFORMATION_COLUMN,
  SUBSCRIBER_INFORMATION_COLUMN,
  ADDITIONAL_INFORMATION_COLUMN
} from "../../../Constants/ComponentConstants/MembershipConstants/DetailsConstants/subscriberDetailsConstant"
import MembershipGrid from "./MembershipGrid";

const SubscriberDetails = (props) => {
  const navigate = useNavigate();
  const {
    selectedUser,
    membership,
    getPrimarySubscriberData,
    getDependentData
  } = props
  const { DASHBOARD } = ROUTES
  const { PRODUCT, COMPLIANCE } = TITLES
  const [showLoading, setShowLoading] = useState(false)
  const [membershipData, setMembershipData] = useState({})
  const gridRef = useRef(null);

  if (emptyObject(selectedUser || {}))
    navigate(DASHBOARD)

  useEffect(() => {
    getData(setShowLoading, selectedUser, getPrimarySubscriberData, getDependentData)
  }, [selectedUser])


  useEffect(() => {
    setMembershipData(apiToFeMapping(membership, selectedUser?.type))
  }, [membership])

  const onGridReady = (params) => {
    gridRef.current = params.api;
  }

  const addressColumn = [
    {
      headerName: 'Street Address',
      field: 'streetAddress',
      minWidth: 250,
      cellRenderer: param => {
        return <>
          {typeof (param?.value) === "object" ? param?.value?.toString() : param?.value}
        </>
      }
    },
    {
      headerName: 'City',
      minWidth: 200,
      field: 'city'
    },

    {
      headerName: 'State',
      minWidth: 150,
      field: 'state'
    },
    {
      headerName: 'Zip Code',
      minWidth: 150,
      field: 'postalCode'
    },
  ]

  return (
    <>
      {
        showLoading ?
          <Loading /> : <>
            {membership?.error ? <>{membership?.error}</> : <></>}
            {emptyObject(selectedUser) ? <></> :
              <Grid className="pb-35" container spacing={2}>
                <h1 className="container" style={{ marginLeft: '10px' }}>{membershipData?.name}</h1>
                <MembershipGrid
                  onGridReady={onGridReady}
                  data={emptyObjectWithEmptyKeys(membershipData['clientInformation']) ? [] : [membershipData['clientInformation']]}
                  title={underscoreToCamel('client_information')}
                  column={CLIENT_INFORMATION_COLUMN}
                  gridRef={gridRef}
                  defaultCofDef={defaultCofDef}
                />
                <MembershipGrid
                  onGridReady={onGridReady}
                  data={emptyObjectWithEmptyKeys(membershipData['employerInformation']) ? [] : [membershipData['employerInformation']]}
                  title={underscoreToCamel('employer_group_information')}
                  column={EMPLOYER_GROUP_INFORMATION_COLUMN}
                  gridRef={gridRef}
                  defaultCofDef={defaultCofDef}
                />
                <MembershipGrid
                  onGridReady={onGridReady}
                  data={emptyObjectWithEmptyKeys(selectedUser.type === "primary" ? membershipData['subscriberInformation'] : membershipData['primaryInformation']) ? [] : [selectedUser.type === "primary" ? membershipData['subscriberInformation'] : membershipData['primaryInformation']]}
                  title={underscoreToCamel('subscriber_demographics')}
                  column={SUBSCRIBER_INFORMATION_COLUMN}
                  gridRef={gridRef}
                  defaultCofDef={defaultCofDef}
                />
                {
                  selectedUser.type === "dependent" ? <>
                    <MembershipGrid
                      onGridReady={onGridReady}
                      data={emptyObjectWithEmptyKeys(membershipData['membershipInformation']) ? [] : [membershipData['membershipInformation']]}
                      title={underscoreToCamel('dependent_demographics')}
                      column={SUBSCRIBER_INFORMATION_COLUMN}
                      gridRef={gridRef}
                      defaultCofDef={defaultCofDef}
                    />
                  </> : <></>
                }
                <MembershipGrid
                  onGridReady={onGridReady}
                  data={emptyObjectWithEmptyKeys(membershipData['primaryAddressScrubbed']) ? [] : [membershipData['primaryAddressScrubbed']]}
                  title={`Primary Address (Scrubbed)`}
                  column={addressColumn}
                  gridRef={gridRef}
                  defaultCofDef={defaultCofDef}
                />
                <MembershipGrid
                  onGridReady={onGridReady}
                  data={emptyObjectWithEmptyKeys(membershipData['primaryAddressUnScrubbed']) ? [] : [membershipData['primaryAddressUnScrubbed']]}
                  title={`Primary Address (Not Scrubbed)`}
                  column={addressColumn}
                  gridRef={gridRef}
                  defaultCofDef={defaultCofDef}
                />
                <MembershipGrid
                  onGridReady={onGridReady}
                  data={emptyObjectWithEmptyKeys(membershipData['alternateAddress']) ? [] : [membershipData['alternateAddress']]}
                  title={`Alternate Address (Scrubbed)`}
                  column={addressColumn}
                  gridRef={gridRef}
                  defaultCofDef={defaultCofDef}
                />
                <MembershipGrid
                  onGridReady={onGridReady}
                  data={emptyObjectWithEmptyKeys(membershipData['privacyAddress']) ? [] : [membershipData['privacyAddress']]}
                  title={`Privacy Address (Scrubbed)`}
                  column={addressColumn}
                  gridRef={gridRef}
                  defaultCofDef={defaultCofDef}
                />
                <MembershipGrid
                  onGridReady={onGridReady}
                  title={`Additional Information`}
                  data={emptyObjectWithEmptyKeys(membershipData['additionalInformation']) ? [] : [membershipData['additionalInformation']]}
                  column={ADDITIONAL_INFORMATION_COLUMN}
                  gridRef={gridRef}
                  defaultCofDef={defaultCofDef}
                />
                <MembershipGrid
                  onGridReady={onGridReady}
                  data={emptyObjectWithEmptyKeys(membershipData['products']) ? [] : membershipData['products']}
                  title={PRODUCT}
                  column={colDef}
                  gridRef={gridRef}
                  domLayout="autoHeight"
                  defaultCofDef={defaultCofDef}
                />
                <MembershipGrid
                  onGridReady={onGridReady}
                  data={emptyObjectWithEmptyKeys(membershipData['complianceInformation']) ? [] : [membershipData['complianceInformation']]}
                  title={COMPLIANCE}
                  column={COMPLIANCE_COLUMN}
                  gridRef={gridRef}
                  defaultCofDef={defaultCofDef}
                />
              </Grid>
            }
          </>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};

const mapActionToProps = {
  getPrimarySubscriberData: getPrimarySubscriberAction,
  getDependentData: getDependentAction,
};

export default connect(mapStateToProps, mapActionToProps)(SubscriberDetails);
