import React, { useState, useMemo, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import '../../../Assets/Css/FileTracking.css'
import Icon from "../../Common/Icon"
import { capitalizeFirstLetter } from "../../../Utils/Common/common.util"
import { getStatusClassName } from "../../../Utils/Widget/PayerEligibilityFileTracking/fileTracking.util"
import { fileTrackingAction } from "../../../Redux/Actions/Widgets/fileTrackingAction"
import { connect } from "react-redux";
import { ROUTES } from "../../../Constants/routesConstant"
import { useNavigate } from "react-router-dom";
import { TITLE, CLASS_NAME } from "../../../Constants/ComponentConstants/WidgetConstants/PayerEligibilityFileTrackingConstants/fileTrackingConstants"
import { COMMON_CLASS, COMMON_TEXT, AG_COLUMN_CENTER } from "../../../Constants/constants"
import { ICON_CONSTANT } from "../../../Constants/iconsConstant"

const Filetracking = (props) => {
  const [rowData, setRowData] = useState([]);
  const { getData, file_tracking: { fileTracking } } = props
  const {
    SEARCH_TITLE,
    LINE,
    FILE_TRACKING_GRID_CLASS,
    FILE_TRACKING_GRID_STATUS_TEXT
  } = CLASS_NAME
  const { AG_THEME_ALPINE, CURSOR_POINTER } = COMMON_CLASS
  const { CUSTOM } = COMMON_TEXT
  const { FILE_DETAILS } = ROUTES
  const { FILE_TRACKING } = TITLE
  const { INSERT_DRIVE_FILE_OUTLINED_ICON } = ICON_CONSTANT
  const navigate = useNavigate();
  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setRowData(fileTracking)
  }, [fileTracking])

  const goToFile = (param) => {
    navigate(FILE_DETAILS, param)
  }

  const columnDefs = [
    {
      headerName: "Payer Name",
      field: "payerName",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      minWidth: 250,
      ...AG_COLUMN_CENTER
    },
    {
      headerName: 'Status', field: 'status', ...AG_COLUMN_CENTER, cellRenderer: (param) => {
        return (
          <>
            {
              !param?.value ?
                <div className={`${getStatusClassName(capitalizeFirstLetter(rowData[param?.node?.rowIndex]?.status))}`}>
                  <span className={FILE_TRACKING_GRID_STATUS_TEXT}>{capitalizeFirstLetter(rowData[param?.node?.rowIndex]?.status)}</span>
                </div> :
                <div className={`${getStatusClassName(capitalizeFirstLetter(param?.value))}`}>
                  <span className={FILE_TRACKING_GRID_STATUS_TEXT}>{capitalizeFirstLetter(param?.value)}</span>
                </div>
            }
          </>
        )
      }
    },
    { headerName: 'Payer Name', field: 'payerName', rowGroup: true, minWidth: 250, hide: true },
    { headerName: 'Date', field: 'date', ...AG_COLUMN_CENTER },
    { headerName: 'File In', field: 'fileIn', ...AG_COLUMN_CENTER },
    { headerName: 'File Out', field: 'fileOut', ...AG_COLUMN_CENTER },
    { headerName: 'Records in Input Files', field: 'recordsInInputFiles', minWidth: 250, ...AG_COLUMN_CENTER },
    { headerName: 'Records rejected by Gateway', field: 'recordsRejectedByGateway', minWidth: 250, ...AG_COLUMN_CENTER },
    { headerName: 'Records errored at DQM', field: 'recordsErroredatDqm', minWidth: 250, ...AG_COLUMN_CENTER },
    { headerName: 'Records rejected by entities', field: 'recordsRejectedByEntities', minWidth: 250, ...AG_COLUMN_CENTER },
    { headerName: 'Record Processed', field: 'recordsProcessed', minWidth: 250, ...AG_COLUMN_CENTER },
    {
      headerName: 'File link', field: 'fileLink', ...AG_COLUMN_CENTER, cellRenderer: (param) => {
        return (
          <>
            {
              param?.value ?
                <Icon
                  className={CURSOR_POINTER}
                  onClick={() => goToFile(param)}
                  name={INSERT_DRIVE_FILE_OUTLINED_ICON}
                /> :
                <></>
            }
          </>
        )
      }
    }
  ];
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 120,
      display: "flex",
      alignItems: "center",
      textAlign: "centre"
    };
  }, []);
  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
      groupSelects: "descendants",
      headerCheckbox: false,
      checkboxes: false,
    };
  }, []);
  return (
    <>
      <h1 className={`${SEARCH_TITLE}`}>{FILE_TRACKING}</h1>
      <div className={`${LINE}`}></div>
      <div className={`${AG_THEME_ALPINE} ${FILE_TRACKING_GRID_CLASS}`}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupDisplayType={CUSTOM}
          groupDefaultExpanded={0}
          rowSelection={rowSelection}
          pagination={true}
          paginationPageSize={10}
          paginationPageSizeSelector={[10, 20, 50, 100, 500, 1000]}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { file_tracking } = state
  return { file_tracking };
};

const mapActionToProps = {
  getData: fileTrackingAction
};

export default connect(mapStateToProps, mapActionToProps)(Filetracking);
