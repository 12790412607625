export const SELECTED_USER = 'SELECTED_USER';
export const ALL_USERS = 'ALL_USERS';
export const COMPONENT_INPUT = 'COMPONENT_INPUT';
export const SAVE_OKTA_TOKEN = 'SAVE_OKTA_TOKEN';
export const MEMBERSHIP_API = 'MEMBERSHIP_API';
export const DEPENDENT_API = 'DEPENDENT_API';
export const MEMBERSHIP_SEARCH_API = 'MEMBERSHIP_SEARCH_API';
export const TOKEN_GENERATION_API = 'TOKEN_GENERATION_API';
export const TOKEN_VERIFY_API = 'TOKEN_VERIFY_API';
export const API_ERR = 'API_ERR';
export const FETCHING_DATA = 'FETCHING_DATA';
export const GET_DASHBOARD_SETTINGS = 'GET_DASHBOARD_SETTINGS';
export const SAVE_DASHBOARD_SETTINGS = 'SAVE_DASHBOARD_SETTINGS';
export const GET_ADMIN_USER = 'GET_ADMIN_USER';
export const GET_ADMIN_TPA_DATA = 'GET_ADMIN_TPA_DATA';
export const SAVE_ADMIN_TPA_DATA = 'SAVE_ADMIN_TPA_DATA';
export const SAVE_ADMIN_USER = 'SAVE_ADMIN_USER';
export const GET_ADMIN_USER_TPA_DATA = 'GET_ADMIN_USER_TPA_DATA';
export const DELETE_ADMIN_USER = 'DELETE_ADMIN_USER';
export const CREATE_ADMIN_USER = 'CREATE_ADMIN_USER';
export const ERROR_TRENDS_MTD_API = 'ERROR_TRENDS_MTD_API';
export const ELIGIBILITY_ERRORS_MTD_API = 'ELIGIBILITY_ERRORS_MTD_API';
export const ELIGIBILITY_ERRORSREJ_MTD_API = 'ELIGIBILITY_ERRORSREJ_MTD_API';
export const PAYER_FILE_RATIO_MTD_API = 'PAYER_FILE_RATIO_MTD_API';
export const SAVE_ADMIN_USER_TPA_DATA = 'SAVE_ADMIN_USER_TPA_DATA';
export const GET_MEMBER_PRODUCTS = 'GET_MEMBER_PRODUCTS';

export const ELIGIBILITY_ERROR_RESULTS_API = 'ELIGIBILITY_ERROR_RESULTS_API';
export const FILE_TRACKING = 'FILE_TRACKING';
export const FILE_DETAILS = 'FILE_DETAILS';
