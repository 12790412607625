import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import "../../../Assets/Css/BasicSearchScreen.css"
import { inputAction } from "../../../Redux/Actions/Common/inputAction"
import { membershipSearchAction } from "../../../Redux/Actions/Membership/memberSearchAction"
import { emptyObject } from "../../../Utils/Common/common.util"
import { ROUTES } from "../../../Constants/routesConstant"
import Icon from "../../Common/Icon"
import CustomModal from '../../Common/CustomModal';

const AdvancedSearchScreen = (props) => {
  const navigate = useNavigate();
  const initialFormData = { groupId: '', membershipId: '', personNumber: '', firstName: '', lastName: '', birthDate: '', gender: '', city: '', state: '', postalCode: '' };
  const [formData, setFormData] = useState(initialFormData);
  const [errorMsg, setErrorMsg] = useState("");
  const [openPopup, setOpenPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    let newValue = value;
    let errorMsg = '';
    const alphaOnlyFields = ['textInput'];
    const alphanumericFields = ['mixedInput'];

    if (alphaOnlyFields.includes(name) && type === 'text') {
      const textRegex = /^[A-Za-z]+$/;
      if (textRegex.test(value)) {
        newValue = value;
      } else {
        newValue = value.replace(/[^A-Za-z]/g, '');
        errorMsg = 'Only alphabetic characters are allowed.';
      }
    }
    if (type === 'number') {
      const maxLength = 12;
      const numericValue = value.replace(/[^0-9]/g, '');
      newValue = numericValue.slice(0, maxLength);
    }
    if (alphanumericFields.includes(name) && type === 'text') {
      newValue = value;
    }
    if (type === 'date') {
      newValue = value;
    }
    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));

    setErrorMsg(errorMsg);
  };
  const handleHelp = () => {
    setOpenPopup(true);
  };
  const handlePopupClose = () => {
    setOpenPopup(false);
  };
  const combinations = [
    "lastName, firstName",
    "lastName, firstName, birthDate",
    "lastName, firstName, postalCode",
    "lastName, firstName, birthDate, postalCode",
    "lastName, firstName, birthDate, postalCode, gender",
    "lastName, firstName, birthDate, membershipId",
    "lastName, firstName, birthDate, postalCode, gender, membershipId",
    "lastName:startsWith, birthDate",
    "lastName:startsWith",
    "lastName:startsWith, firstName:startsWith",
    "lastName:startsWith, firstName:startsWith, birthDate",
    "lastName:startsWith, firstName:startsWith, postalCode",
    "lastName:startsWith, firstName:startsWith, state",
    "lastName:startsWith, firstName:startsWith, city, state",
    "lastName:startsWith, firstName:startsWith, birthDate, postalCode",
    "membershipId, groupId",
    "membershipId",
    "membershipId, groupId, personNumber",
    "membershipId, birthDate",
  ];
  const prepareContent = (combinations) => {
    return (
      <ul style={{listStyleType:'none'}} >
        {combinations.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  const content = prepareContent(combinations);

  const handleSearch = async (e) => {
    e.preventDefault();
    const { firstName, lastName, birthDate, groupId, membershipId, personNumber, gender, city, state, postalCode } = formData;

    const advancedSearchdValidCombinations = [
      ['lastName'],
      ['firstName', 'lastName'],
      ['firstName', 'lastName', 'birthDate'],
      ['firstName', 'lastName', 'postalCode'],
      ['firstName', 'lastName', 'birthDate', 'postalCode'],
      ['firstName', 'lastName', 'birthDate', 'postalCode', 'gender'],
      ['firstName', 'lastName', 'birthDate', 'membershipId'],
      ['firstName', 'lastName', 'birthDate', 'postalCode', 'gender', 'membershipId'],
      ['lastName:startsWith', 'birthDate'],
      ['lastName:startsWith'],
      ['lastName:startsWith', 'firstName:startsWith'],
      ['lastName:startsWith', 'firstName:startsWith', 'birthDate'],
      ['lastName:startsWith', 'firstName:startsWith', 'postalCode'],
      ['lastName:startsWith', 'firstName:startsWith', 'state'],
      ['lastName:startsWith', 'firstName:startsWith', 'city', 'state'],
      ['lastName:startsWith', 'firstName:startsWith', 'birthDate', 'postalCode'],
      ['membershipId', 'groupId'],
      ['membershipId'],
      ['membershipId', 'birthDate'],
      ['membershipId', 'groupId', 'personNumber']
    ];
    function isValidCombination(providedParams) {
      const result = advancedSearchdValidCombinations.some(combination =>
        combination.length === providedParams.length &&
        combination.every(field => providedParams.includes(field))
      );
      return result;
    }
    const providedParams = Object.keys(formData).filter(key => formData[key] !== '');

    if (!isValidCombination(providedParams)) {
      alert("Please click on Help icon in the Eligibility search widget to know valid combinations that has to be entered for getting search results");
      setTimeout(() => setErrorMsg(''), 3000);
      return;
    }
    props.input({ ...props.component_input, 'search': formData })
    if (props?.component_input?.search && !emptyObject(props?.component_input?.search)) {
      const searchQuery = {};
      if (firstName) {
        searchQuery['firstName:startsWith'] = firstName;
      }
      if (lastName) {
        searchQuery['lastName:startsWith'] = lastName;
      }
      if (birthDate) {
        searchQuery['birthDate'] = birthDate;
      }
      if (groupId) {
        searchQuery['groupId'] = groupId;
      }
      if (membershipId) {
        searchQuery['membershipId'] = membershipId;
      }
      if (personNumber) {
        searchQuery['personNumber'] = personNumber;
      }
      if (city) {
        searchQuery['city'] = city;
      }
      if (state) {
        searchQuery['state'] = state;
      }
      if (postalCode) {
        searchQuery['postalCode'] = postalCode;
      }
      if (gender) {
        searchQuery['gender'] = gender;
      }
           await props.getSearchData(searchQuery)
      navigate(`${ROUTES.SUBSCRIBER.SEARCH_RESULT}`);
    }
  };

  const handleReset = () => {
    setFormData(initialFormData);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
     e.preventDefault();
      handleSearch(e);
    }
  }

  return (
    <div className="search-form">      
      <Typography variant="h5" gutterBottom="true" sx={{color:  "#035c67", fontWeight: '600'}}>
        Eligibility Advanced Search
        <Icon name="InfoIcon" onClick={handleHelp} className="adv-help-icon" />
      </Typography>
      <Typography variant="body2" gutterBottom="true" sx={{marginBottom: 2}}>
        To view an Alpha list of participants, enter a partial last name followed by an astrick, or followed by a partial last name and partial first name followed by an astrick . Entry of a groupid is required
        to limit the search criteria. Organization Id and Comp(?) may also be entered to further limit the search criteria.
       
      </Typography>
      <div className="first-group">
        <div className="form-group">
          <b>Group id</b>
          <input
            type="text"
            name="groupId"
            value={formData.groupId}
            onChange={handleChange}
            maxLength={12}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">Maximum of 12 Digits</small>
        </div >
        <div className="form-group">
          <b>Membership id</b>
          <input
            use type="number"
            name="membershipId"
            value={formData.membershipId}
            onChange={handleChange}
            maxLength={12}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">Maximum of 12 Digits</small>
        </div>
        <div className="form-group">
          <b>Person number</b>
          <input
            use type="text"
            name="personNumber"
            value={formData.personNumber}
            onChange={handleChange}
            maxLength={12}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">Maximum of 12 Digits</small>
        </div>
      </div>
      <div className="second-group">
        <div className="form-group">
          <b>First name</b>
          <input
            id="textInput"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            maxLength="25"
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">up to 25 characters</small>
        </div>
        <div className="form-group">
          <b>Last name</b>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            maxLength="25"
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">up to 25 characters</small>
        </div>
        <div className="form-group">
          <b>birthDate</b>
          <input
            type="date"
            name="birthDate"
            value={formData.birthDate}
            onChange={handleChange}
            onKeyDown={(e) => handleKeyPress(e)}
          />
        </div>
        <div className="form-group">
          <b>Gender</b>
          <input
            type="text"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            maxLength="25"
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">up to 25 characters</small>
        </div>
        <div className="form-group">
          <b>City</b>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            maxLength="25"
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">up to 25 characters</small>
        </div>
        <div className="form-group">
          <b>State</b>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            maxLength="25"
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">up to 25 characters</small>
        </div>
        <div className="form-group">
          <b>Postal code</b>
          <input
            use type="number"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            maxLength={9}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <small className="placeholder">Maximum of 9 digits</small>
        </div>
      </div>
      <hr className="line" />
      <div className="button-group">
        <button className="greentext" onClick={handleReset}>Reset</button>
        <button type="submit" className="greenfilled" onClick={handleSearch}>Search</button>
      </div>
      <div className="errmsg">
        {errorMsg && (
          <div className="errmsg" id="errormsgs">
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMsg}
            </Alert>
          </div>
        )}
      </div>
      <CustomModal
        open={openPopup}
        handleClose={handlePopupClose}
        title="Advanced Search Combinations"
        content={content}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return { ...state };
};
const mapActionToProps = {
  input: inputAction,
  getSearchData: membershipSearchAction
};
export default connect(mapStateToProps, mapActionToProps)(AdvancedSearchScreen);