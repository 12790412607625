const mockTrackingData = [
  {
    Group_ID: '1234567',
    Organization: '1234567',
    Comp: '1234567',
    primary_participant: 'Primary',
    part_id: '12344567',
    ssn: '123-45-6789',
    user_id: '123142 ',
    modified_time: '12-14-2023, 12:30 ',
  },
  {
    Group_ID: '1234567',
    Organization: '1234567',
    Comp: '1234567',
    primary_participant: 'Dependent',
    part_id: '23423462',
    ssn: '123-45-6789',
    user_id: '123124 ',
    modified_time: '12-14-2023, 16:25',
  },
];

export default mockTrackingData;
